import React from 'react';
import LandingPage from '@artemis/containers/LandingPage';
import {
  LANDING_PAGE_STRINGS,
  QUERY_LIMIT,
} from '@artemis/containers/LandingPage/constants';
import { getPageFetchFilter } from '@artemis/containers/LandingPage/utils';
import { loadNearbyMerchants } from '@artemis/store/catalog/slice';
import { loadResourceSet } from '@artemis/store/resourceSet/slice';
import { getAnalyticsIdentity } from '@artemis/store/user/slice';
import commonStrings from '@artemis/utils/common-strings.json';

const Landing = props => <LandingPage {...props} />;

// This gets called on every request
Landing.getInitialProps = async ctx => {
  const { contentfulLocale, isServer, query, req, store } = ctx;
  // Fetch data from external API
  if (isServer) {
    store.dispatch(
      loadResourceSet({
        name: [...LANDING_PAGE_STRINGS, ...commonStrings],
        locale: contentfulLocale,
      }),
    );

    const filter = getPageFetchFilter(query, req);
    if (filter.latitude && filter.longitude) {
      store.dispatch(loadNearbyMerchants({ limit: QUERY_LIMIT, ...filter }));
    }
    // Required to get entity id from athena for analytics since
    // all requests are made on this page are from content-gateway
    store.dispatch(getAnalyticsIdentity());

    return { initialFilters: filter };
  }
  return {};
};

export default Landing;
